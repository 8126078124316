import React from 'react'


const SectionDiv = ({children} : any) =>  {
     return (        
   <div className="section-div">
     {children}
      </div> )
}


    export default SectionDiv
